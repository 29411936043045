import wrapWithProvider from './wrapWithProvider';
import { gaPageView, mpPageView } from 'utils/analytics';

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation && prevLocation.pathname !== location.pathname) {
    gaPageView(location.pathname);
    mpPageView();
  }
};

export const wrapRootElement = wrapWithProvider;
