const NOT_FOUND_PAGE = '/404';

exports.linkResolver = (doc) => {
  // if (doc.isBroken) {
  //   return NOT_FOUND_PAGE;
  // }

  try {
    switch (doc?.type) {
      case 'about_page':
        return '/about-us/';
      case 'announcement_banner':
        return '/';
      case 'article': {
        // If no article fields were sent through, we can't figure out the correct URL, so we go
        // for the default one
        if (!doc.data) {
          return `/blog/${doc.uid}/`;
        }

        // If we have the article's type, we can construct the correct URL
        const articleType = doc.data.article_type || 'blog';

        switch (articleType) {
          // case 'privacy policy':
          //   return '/privacypolicy';
          case 'terms of service':
            return '/termsofservice/';
          default:
            return `/${articleType}/${doc.uid}/`;
        }
      }
      case 'article_category':
        return `/category/${doc.uid}/`;
      case 'article_collection':
        return `/collection/${doc.uid}/`;
      case 'article_overview_page':
        const page = doc.currPage;
        if (page > 1) {
          return `/${doc.uid}/p/${page}/`;
        }
        return `/${doc.uid}/`;
      case 'article_tag':
        return `/tag/${doc.uid}/`;
      case 'call_to_action_module':
        return '/';
      case 'confirmation_page':
        return `/confirmation/${doc.uid}/`;
      case 'connected_awards':
        return `/connected-awards/${doc.uid}/`;
      case 'connected_podcast':
        return '/connected-podcast/';
      case 'privacy_page':
        if (doc.uid === 'current') return '/privacypolicy/';
        return `/privacypolicy/${doc.uid}/`;
      case 'legal_page':
        return `/legal/${doc.uid}/`;
      case 'workflow':
        return `/workflow/${doc.uid}/`;
      case 'learning_center':
        return '/learning-center/';
      case 'learning_track':
        return `/learning-track/${doc.uid}/`;
      case 'landing_page': {
        const topLevelDomain = doc.data?.top_level_domain;

        if (topLevelDomain === 'yes') {
          return `/${doc.uid}/`;
        }

        return `/lpg/${doc.uid}/`;
      }
      case 'use_cases':
        return '/use-cases/';
      case 'use_cases_detail':
        return `/use-cases/${doc.uid}/`;
      case 'paid_landing_page':
        return `/p/${doc.uid}/`;
      case 'cookie_page':
        if (doc.uid === 'current') {
          return '/cookie-policy/';
        }
        return `/cookie-policy/${doc.uid}/`;
      case 'careers_page':
        return '/careers/';
      case 'company':
        return '/';
      case 'contact_page_template': {
        switch (doc.uid) {
          case 'default':
            return '/contact/';
          default:
            return `/contact/${doc.uid}/`;
        }
      }
      case 'contact_page': {
        switch (doc.uid) {
          case 'default':
            return '/contact/';
          case 'get-started':
            return '/get-started/';
          default:
            return `/contacts/${doc.uid}/`;
        }
      }
      case 'solution_page': {
        switch (doc.uid) {
          case 'retail':
            return '/solutions/retail/';
          case 'financial-services':
            return '/solutions/financial-services/';
          case 'media':
            return '/solutions/media/';
          case 'qsr':
            return '/solutions/qsr/';
          case 'gaming':
            return '/solutions/gaming/';
          case 'travel':
            return '/solutions/travel/';
          case 'marketers':
            return '/solutions/marketers/';
          case 'product':
            return '/solutions/product/';
          case 'developers':
            return '/solutions/developers/';
          default:
            return `/solutions/${doc.uid}/`;
        }
      }
      case 'solution_vertical': {
        switch (doc.uid) {
          case 'retail':
            return '/solutions/retail/';
          case 'financial-services':
            return '/solutions/financial-services/';
          case 'media':
            return '/solutions/media/';
          case 'qsr':
            return '/solutions/qsr/';
          case 'gaming':
            return '/solutions/gaming/';
          case 'travel':
            return '/solutions/travel/';
          case 'marketers':
            return '/solutions/marketers/';
          case 'product':
            return '/solutions/product/';
          case 'developers':
            return '/solutions/developers/';
          default:
            return `/solutions/${doc.uid}/`;
        }
      }
      case 'cookie_banner':
        return '/';
      case 'customers_page':
        return '/customers/';
      case 'events_page':
        return '/events/';
      case 'home_page':
        return '/';
      case 'integration_page_template':
        return '/integration/amplitude/';
      case 'integrations_page':
        return '/integrations/';
      case 'join_the_platform_page':
        return '/technology-partners/';
      case 'navigation':
        return '/';
      case 'newsletter_archive':
        return '/newsletters/';
      case 'newsletter_detail':
        return `/newsletters/${doc.uid}/`;
      case 'partners_pag': // Yeah, I know
        return '/certified-solution-partners/';
      case 'partner_hub':
        return '/partner/';
      case 'value_based_pricing':
        return '/pricing/';
      case 'certified_data_partners':
        return '/data-partners/';
      case 'periodic_table_page':
        return '/periodictable/';
      case 'platform_overview_page':
        return '/platform/overview/';
      case 'platform_demo':
        return `/explore-platform/`;
      case 'platform_demo_vertical': {
        return `/explore-platform/${doc.uid}/`;
      }
      case 'product': {
        return `/product/${doc.uid}/`;
      }
      case 'products': {
        return `/platform/${doc.uid}/`;
      }
      case 'platform_page': {
        return `/platform/`;
      }
      case 'platform_detail_page': {
        return `/platform/${doc.uid}/`;
      }
      case 'product_detail_page': {
        return `/platform/detail/${doc.uid}/`;
      }
      case 'trust_center':
        return '/trust-center/';
      case 'security':
        return '/security/';
      case 'services_pricing':
        return '/services-pricing/';
      case 'solution': {
        return `/solutions/${doc.uid}/`;
      }
      case 'solution_use_case': {
        return `/solutions/${doc.uid}/`;
      }
      case 'solutions_partner': {
        return `/certified-solution-partners/${doc.uid}/`;
      }
      default:
        return '/';
    }
  } catch (err) {
    console.log(err, doc);
    return NOT_FOUND_PAGE;
  }
};
